<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Proveedores</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">
                    <router-link to="/Tif">Tif</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Proveedores</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Proveedores</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="">Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Password"
                        v-model="form.nombre"
                        :class="
                          $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="">Contacto</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Contacto"
                        v-model="form.contacto"
                        :class="
                          $v.form.contacto.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="">Telefono</label>
                      <input
                        type="text"
                        v-model="form.telefono"
                        placeholder="Telefono"
                        class="form-control form-control-sm"
                        :class="
                          $v.form.telefono.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                  <button
                    class="btn bg-primary col-md-1"
                    @click="save()"
                    v-if="
                      !$v.form.$invalid &&
                      ($store.getters.can('tif.proveedores.edit') ||
                        $store.getters.can('tif.proveedores.create'))
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card bg-light">
                  <div class="card-header bg-navy">
                    <h6 class="mb-0">
                      <b>Detalle Proveedor</b>
                    </h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="empresa_id" class="col-md-5"
                            >Empresa</label
                          >
                          <v-select
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="empresa"
                            placeholder="Empresa"
                            label="razon_social"
                            :options="listasForms.empresas"
                            style="font-size: 15px; background-color: #fff"
                            @input="selectEmpresas()"
                          ></v-select>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group">
                          <label for="">Url</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Url"
                            v-model="form1.url"
                            :class="
                              $v.form1.url.$invalid ? 'is-invalid' : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="">Codigo Empresa</label>
                          <input
                            type="text"
                            v-model="form1.codigo_empresa"
                            placeholder="Codigo Empresa"
                            class="form-control form-control-sm"
                            :class="
                              $v.form1.codigo_empresa.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="">Username</label>
                          <input
                            type="text"
                            v-model="form1.username"
                            placeholder="Username"
                            class="form-control form-control-sm"
                            :class="
                              $v.form1.username.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="">Password</label>
                          <input
                            type="password"
                            class="form-control form-control-sm"
                            v-model="form1.password"
                            placeholder="Password"
                            :class="
                              $v.form1.password.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-1">
                        <div class="form-group">
                          <button
                            class="btn bg-primary mt-4"
                            v-if="!$v.form1.$invalid"
                            @click="saveDetalle()"
                          >
                            <i class="fas fa-check"></i><br />
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- TABLA OPERACION -->
                    <div class="row text-center mt-4">
                      <div class="col-md-4"></div>
                      <div class="col-md-4">
                        <table class="table">
                          <thead class="table-secondary">
                            <tr>
                              <th style="width: 60%">Empresa</th>
                              <th style="width: 40%"># Vehículos</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="detSolicitud in detSolicitudes"
                              :key="detSolicitud.id"
                            >
                              <td>{{ detSolicitud.empresa.razon_social }}</td>
                              <td>{{ detSolicitud.canVehi }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "TifProveedoresForm",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      proveedores: {},
      detSolicitudes: [],
      form: {
        nombre: null,
        contacto: null,
        telefono: null,
        vehiculos: [],
      },
      form1: {
        empresa_id: null,
        url: null,
        codigo_empresa: null,
        username: null,
        password: null,
      },
      accion: "",
      metodo: "",
      empresa: [],
      //listas
      listasForms: {
        empresas: [],
      },
    };
  },
  validations() {
    return {
      form: {
        nombre: {
          required,
        },
        contacto: {
          required,
        },
        telefono: {
          required,
        },
      },
      form1: {
        empresa_id: {
          required,
        },
        url: {
          required,
        },
        username: {
          required,
        },
        password: {
          required,
        },
        codigo_empresa: {
          required,
        },
      },
    };
  },

  methods: {
    async init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        let id = this.$route.params.item.id;
        if (this.$route.params.item.tif_det_proveedores) {
          this.detSolicitudes = this.$route.params.item.tif_det_proveedores;
        }
        await this.show(id);
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
      this.cargando = false;
    },

    async show(id) {
      await axios.get("/api/tif/proveedores/show/" + id).then((response) => {
        this.form = response.data;
      });
      for (let i = 0; i < this.detSolicitudes.length; i++) {
        let cantVehi = 0;
        for (let j = 0; j < this.form.vehiculos.length; j++) {
          if (
            this.detSolicitudes[i].empresa_id ==
            this.form.vehiculos[j].empresa_id
          ) {
            cantVehi = cantVehi + 1;
          }
        }
        this.detSolicitudes[i]["canVehi"] = cantVehi;
      }
    },

    save() {
      this.cargando = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/tif/proveedores",
          data: this.form,
        })
          .then((response) => {
            this.$route.params.accion = "Editar";
            this.$route.params.item = response.data;
            this.init();
            this.$swal({
              icon: "success",
              title: "Los datos del proveedor se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async saveDetalle() {
      this.form1.tif_proveedor_id = this.form.id;
      this.cargando = true;
      if (!this.$v.form1.$invalid) {
        await axios({
          method: "POST",
          url: "/api/tif/proveedores/detalle",
          data: this.form1,
        })
          .then((response) => {
            this.detSolicitudes.push(response.data);
            this.show(this.form.id);
            this.$swal({
              icon: "success",
              title: "El detalle del proveedor se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getEmpresas() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    selectEmpresas() {
      this.form1.empresa_id = this.empresa.id;
    },

    back() {
      return this.$router.replace("/Tif/TifProveedores");
    },
  },

  async mounted() {
    await this.init();
    await this.getEmpresas();
  },
};
</script>
